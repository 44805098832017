<template>
    <!--EvaluationEconomic start--> 
    <div class="box_economic_evaluation">
        <template v-for="(items, key) in data" :key="key">
            <table class="comparateur2 border-none" id="economic_evaluation">   
                <tbody>
                    <tr>
                        <td class="bg-orange no-border" rowspan="2"><span> 
                            {{$t('Type of economic analysis')}}: <br> <strong> {{ items['type_of_economic_analysis'] ? $t(items['type_of_economic_analysis']) : "-" }} </strong>
                        </span></td>
                        <td style="margin-top: 0; padding: 10px;" > 
                            {{$t('Type of economic analysis')}} : 
                            <span class="text-orange"> {{ items['type_of_economic_analysis'] ? $t(items['type_of_economic_analysis']) : "-" }} </span>                                        
                        </td> 
                        <td  class="no-border" rowspan="2" style="text-align: center; width: 90px;">
                            <a @click="collapse_show = !collapse_show">
                                <em class="icons icon-plus-m" :class="collapse_show ? 'icon-arrow-down' : ''"></em>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="margin-top: 0; padding: 10px;" >                           
                            {{$t('Health outcome')}} :
                            <span class="text-orange"> {{items['health_outcome'] ? items['health_outcome'] : "-"}} </span>                                   
                        </td>
                    </tr>
                </tbody>
            </table>

            
            <div :key="'collapse'+key" v-if="collapse_show">
                <template v-for="(item_analysis, key_analysis) in items.economic_evaluation_analysis" :key="key_analysis+'collapse'+1">
                <table class="comparateur2 text-left" :id="'economic_evaluation_analysis-1-'+key_analysis">
                    <thead>
                        <tr class="tr-pointer">
                            <th colspan="2" style="text-align: center">Method</th>
                        </tr>
                    </thead>
                    <tbody v-if="condition_show(item_analysis)">
                        <tr class="economic-title">
                            <td colspan="2"><strong>Analytic Framwork</strong> </td>
                        </tr>

                        <template v-if="item_analysis['method_patients_population_ma_population'] ||
                                    item_analysis['method_patients_population_evidence_based_documented_population'] ||
                                    item_analysis['method_patients_population_simulated_population']">

                            <tr class="subdata-tr-analytic_framwork"  >
                                <td colspan="2">Patients population (indication,line,subgroup)</td>
                            </tr>
                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['method_patients_population_ma_population']">
                                <td style="width: 25%;">MA population</td>
                                <td style="width: 75%;"> <span v-html="item_analysis['method_patients_population_ma_population'] ? item_analysis['method_patients_population_ma_population'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['method_patients_population_evidence_based_documented_population']">
                                <td style="width: 25%;">Evidence based documented population</td>
                                <td> <span v-html="item_analysis['method_patients_population_evidence_based_documented_population'] ? item_analysis['method_patients_population_evidence_based_documented_population'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['method_patients_population_simulated_population']">
                                <td style="width: 25%;">Simulated population</td>
                                <td> <span v-html="item_analysis['method_patients_population_simulated_population'] ? item_analysis['method_patients_population_simulated_population'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['method_perspective']">
                                <td style="width: 25%;">Perspective</td>
                                <td> <span v-html="item_analysis['method_perspective'] ? item_analysis['method_perspective'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['method_time_horizon']">
                                <td style="width: 25%;">Time horizon</td>
                                <td> <span v-html="item_analysis['method_time_horizon'] ? item_analysis['method_time_horizon'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['method_cycle_lenth']">
                                <td style="width: 25%;">Cycle length</td>
                                <td> <span v-html="item_analysis['method_cycle_lenth'] ? item_analysis['method_cycle_lenth'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['method_intervention']">
                                <td style="width: 25%;">Intervention</td>
                                <td> <span v-html="item_analysis['method_intervention'] ? item_analysis['method_intervention'] : '-'"></span> </td>
                            </tr>

                            <template v-if="item_analysis['economic_evaluation_analysis_comparator'].length > 0">
                                <tr class="subdata-tr-analytic_framwork"  >
                                    <td colspan="2">Comparators&nbsp;</td>
                                </tr>
                                <tr class="subdata-tr-analytic_framwork" v-for="(valuecom, keycom) in item_analysis['economic_evaluation_analysis_comparator']" :key="'economic_evaluation_analysis_comparator'+keycom">
                                    <td style="width: 25%;text-indent: 30px;"> Comparators {{key + 1}}</td>
                                    <td> <span v-html="valuecom['comparator']"></span> </td>
                                </tr>
                            </template>


                            <template v-if="item_analysis['discount_rate_effects'] || item_analysis['discount_rate_costs']">
                                <tr class="subdata-tr-analytic_framwork">
                                    <td colspan="2">Annual discount rate&nbsp;</td>
                                </tr>                         
                                <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['discount_rate_effects']">
                                    <td style="width: 25%;text-indent: 30px;">Effects</td>
                                    <td> <span v-html="item_analysis['discount_rate_effects'] ? item_analysis['discount_rate_effects'] + '%' : '-'"></span> </td>
                                </tr>
                                <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['discount_rate_costs']">
                                    <td style="width: 25%;text-indent: 30px;">Costs</td>
                                    <td> <span v-html="item_analysis['discount_rate_costs'] ? item_analysis['discount_rate_costs'] + '%' : '-'"></span> </td>
                                </tr>
                            </template>

                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['choice_of_model']">
                                <td style="width: 25%;">Choice of model</td>
                                <td> <span v-html="item_analysis['choice_of_model'] ? item_analysis['choice_of_model'] : '-'"></span> </td>
                            </tr>

                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['structure_model']">
                                <td style="width: 25%;">Structure of the model</td>
                                <td> <span v-html="item_analysis['structure_model'] ? item_analysis['structure_model'] : '-'"></span> </td>
                            </tr>

                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['image_name']">
                                <td style="width: 25%;">Model picture</td>
                                <td>
                                    <LinkUpload :href="'/uploads/EconomicEvaluationAnalysis/'+item_analysis['id']+'/image.'+extension(item_analysis['image'])" target="_blank" :newtab="true" rel="noopener">
                                        <ImageUpload :src="'/uploads/EconomicEvaluationAnalysis/'+item_analysis['id']+'/image.'+extension(item_analysis['image'])" style=" width: 100%" alt="" ></ImageUpload>                                                
                                    </LinkUpload>   
                                </td>
                            </tr>

                            <tr class="subdata-tr-analytic_framwork" v-if="item_analysis['administration_dosage']">
                                <td style="width: 25%;">Administration / Dosage</td>
                                <td> <span v-html="item_analysis['administration_dosage'] ? item_analysis['administration_dosage'] : '-'"></span> </td>
                            </tr>

                            <template v-if="item_analysis['economic_evaluation_analysis_assumption'].length > 0">
                                <tr class="subdata-tr-analytic_framwork"  >
                                    <td colspan="2">Assumptions&nbsp;</td>
                                </tr> 
                                <tr class="subdata-tr-analytic_framwork" v-for="(valueAssumption, keyAssumption) in item_analysis['economic_evaluation_analysis_assumption']" :key="'economic_evaluation_analysis_assumption'+keyAssumption">
                                    <td style="width: 25%;text-indent: 30px;">Assumptions&nbsp; {{keyAssumption + 1}}</td>
                                    <td> <span v-html="valueAssumption['assumptions']"></span></td>
                                </tr>
                            </template>
                        </template>


                        <!--Measurement of effectiveness-->
                        <template v-if="item_analysis['clinical_data_for_the_product'] ||
                                item_analysis['review_of_literature_for_comparators'] ||
                                item_analysis['meta_analysis_method_for_comparators'] ||
                                item_analysis['extrapolation_method'] ||
                                item_analysis['comment']">
                            <tr class="economic-title">
                                <td colspan="2"><strong>Measurement of effectiveness</strong> </td>
                            </tr>
                            <tr class="subdata-tr-measurement_effectiveness" v-if="item_analysis['clinical_data_for_the_product']">
                                <td style="width: 25%;">Clinical data (RCT or RWD) for the product</td>
                                <td> <span v-html="item_analysis['clinical_data_for_the_product'] ? item_analysis['clinical_data_for_the_product'] : '-'"></span> </td>
                            </tr>         
                            <tr class="subdata-tr-measurement_effectiveness" v-if="item_analysis['review_of_literature_for_comparators']">
                                <td style="width: 25%;">Literature review for the intervention/comparators</td>
                                <td> <span v-html="item_analysis['review_of_literature_for_comparators'] ? item_analysis['review_of_literature_for_comparators'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-measurement_effectiveness" v-if="item_analysis['meta_analysis_method_for_comparators']">
                                <td style="width: 25%;">Meta-analysis method for comparators</td>
                                <td> <span v-html="item_analysis['meta_analysis_method_for_comparators'] ? item_analysis['meta_analysis_method_for_comparators'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-measurement_effectiveness" v-if="item_analysis['extrapolation_method']">
                                <td style="width: 25%;">Extrapolation method</td>
                                <td> <span v-html="item_analysis['extrapolation_method'] ? item_analysis['extrapolation_method'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-measurement_effectiveness" v-if="item_analysis['comment']">
                                <td style="width: 25%;">Comment</td>
                                <td> <span v-html="item_analysis['comment'] ? item_analysis['comment'] : '-'"></span> </td>
                            </tr>
                        </template>


                        <!--Extrapolation-->
                        <template v-if="item_analysis['economic_evaluation_analysis_extrapolation'].length > 0">
                            <tr class="subdata-tr-analytic_extrapolation tr-pointer economic-title">
                                <td colspan="2"><strong>Extrapolation</strong> </td>
                            </tr>
                            <template v-for="(valueExtrapolation, keyExtrapolation) in item_analysis['economic_evaluation_analysis_extrapolation']" :key="keyExtrapolation+'endpoint'">
                                <tr class="subdata-tr-analytic_extrapolation" >
                                    <td style="width: 25%;text-indent: 30px;">Endpoint&nbsp;</td>
                                    <td> <span v-html="valueExtrapolation['endpoint']"></span> </td>
                                </tr>
                                <tr class="subdata-tr-analytic_extrapolation" >
                                    <td style="width: 25%;text-indent: 30px;">Method&nbsp;</td>
                                    <td> <span v-html="valueExtrapolation['method']"></span> </td>
                                </tr>
                                <tr class="subdata-tr-analytic_extrapolation">
                                    <td style="width: 25%;text-indent: 30px;">Source&nbsp;</td>
                                    <td> <span v-html="valueExtrapolation['source']"></span> </td>
                                </tr>
                            </template> 
                        </template>

                        <!--Utility valorisation-->
                        <template v-if="item_analysis['economic_evaluation_analysis_utility_value'].length > 0">   
                            <tr class="subdata-tr-utility_valorisation tr-pointer economic-title">
                                <td colspan="2"><strong>Utility Values</strong> </td>
                            </tr>
                            <template v-for="(valueUtilityValue, keyUtilityValue) in item_analysis['economic_evaluation_analysis_utility_value']" :key="keyUtilityValue+'preference_scoring_method'">
                            
                                <tr class="subdata-tr-utility_valorisation" >
                                    <td style="width: 25%;text-indent: 30px;">Preference scoring method&nbsp;</td>
                                    <td> <span v-html="valueUtilityValue['preference_scoring_method']"></span> </td>
                                </tr>
                                <tr class="subdata-tr-utility_valorisation">
                                    <td style="width: 25%;text-indent: 30px;">Sources&nbsp;</td>
                                    <td> <span v-html="valueUtilityValue['sources']"></span> </td>
                                </tr>
                                <tr class="subdata-tr-utility_valorisation">
                                    <td style="width: 25%;text-indent: 30px;">Comment&nbsp;</td>
                                    <td> <span v-html="valueUtilityValue['utility_comment']"></span> </td>
                                </tr>
                            </template>
                        </template>  
                        

                        <!--Valorisation and costs-->
                        <template v-if="item_analysis['currency_and_price_date_date'] ||
                            item_analysis['currency_and_price_date_currency'] ||
                            item_analysis['resources'] ||
                            item_analysis['resources_sources'] ||
                            item_analysis['type_of_costs'] ||
                            item_analysis['type_of_costs_sources']"> 

                            <tr class="economic-title">
                                <td colspan="2"><strong>Resources and costs</strong> </td>
                            </tr>
                            <tr class="subdata-tr-valorisation_and_costs" v-if="item_analysis['resources']">
                                <td style="width: 25%;">Resources </td>
                                <td> <span v-html="item_analysis['resources'] ? item_analysis['resources'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-valorisation_and_costs" v-if="item_analysis['resources_sources']">
                                <td style="width: 25%;">Sources</td>
                                <td> <span v-html="item_analysis['resources_sources'] ? item_analysis['resources_sources'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-valorisation_and_costs" v-if="item_analysis['type_of_costs']">
                                <td style="width: 25%;">Type of costs </td>
                                <td> <span v-html="item_analysis['type_of_costs'] ? item_analysis['type_of_costs'] : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-valorisation_and_costs " v-if="item_analysis['type_of_costs_sources']">
                                <td style="width: 25%;">Sources</td>
                                <td> <span v-html="item_analysis['type_of_costs_sources'] ? item_analysis['type_of_costs_sources'] : '-'"></span> </td>
                            </tr>
                        </template>

                        
                        <template v-if="item_analysis['currency_and_price_date_date'] || 
                                        item_analysis['currency_and_price_date_currency']">  
                            <tr class="subdata-tr-valorisation_and_costs economic-title" >
                                <td colspan="2"><strong> Currency and price date</strong></td>                                        
                            </tr>

                            <tr class="subdata-tr-valorisation_and_costs" v-if="item_analysis['currency_and_price_date_date']">
                                <td style="width: 25%;">Date of collection</td>
                                <td> <span v-html="item_analysis['currency_and_price_date_date'] ? DDMMMYY(item_analysis['currency_and_price_date_date']) : '-'"></span> </td>
                            </tr>
                            <tr class="subdata-tr-valorisation_and_costs" v-if="item_analysis['currency_and_price_date_currency']">
                                <td style="width: 25%;">Currency</td>
                                <td> <span v-html="item_analysis['currency_and_price_date_currency'] ? item_analysis['currency_and_price_date_currency'] : '-'"></span> </td>
                            </tr>
                        </template> 


                        <!--Incertitude exploration-->
                        <template v-if="item_analysis['structural_sensibility_analysis'] ||
                                item_analysis['deterministic_sensibility_analysis'] ||
                                item_analysis['probabilistic_sensibility_analysis'] ||
                                item_analysis['scenario_analysis']">

                            <tr class="subdata-tr-incertitude_exploration tr-pointer economic-title">
                                <td colspan="2"><strong>Sensitivity analysis</strong> </td>
                            </tr>
                            <tr class="subdata-tr-incertitude_exploration" v-if="item_analysis['structural_sensibility_analysis']">
                                <td style="width: 25%;">Structural sensibility analysis</td>
                                <td> {{item_analysis['structural_sensibility_analysis'] ? "Yes" : "No"}} </td>
                            </tr>
                            <tr class="subdata-tr-incertitude_exploration" v-if="item_analysis['deterministic_sensibility_analysis']">
                                <td style="width: 25%;">Deterministic sensibility analysis (Tornado)</td>
                                <td> {{item_analysis['deterministic_sensibility_analysis'] ? "Yes" : "No"}} </td>
                            </tr>
                            <tr class="subdata-tr-incertitude_exploration" v-if="item_analysis['probabilistic_sensibility_analysis']">
                                <td style="width: 25%;">Probabilistic sensibility analysis</td>
                                <td> {{item_analysis['probabilistic_sensibility_analysis'] ? "Yes" : "No"}}</td>
                            </tr>
                            <tr class="subdata-tr-incertitude_exploration" v-if="item_analysis['scenario_analysis']">
                                <td style="width: 25%;">Scenario analysis</td>
                                <td>{{item_analysis['scenario_analysis'] ? "Yes" : "No"}} </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

            <template v-if="item_analysis['discussion_study_finding'] ||
                            item_analysis['discussion_assumptions'] ||
                            item_analysis['discussion_comparision_with_other_studies'] ||
                            item_analysis['discussion_validation'] ||
                            item_analysis['discussion_generalizability_transferability'] ||
                            item_analysis['discussion_extrapolation'] ||
                            item_analysis['discussion_conclusion'] ||
                            item_analysis['discussion_comment']">
                <table class="comparateur2 text-left" align="center" :key="key+key_analysis+'0'" :id="'economic_evaluation_analysis-2-'+key_analysis">
                    <thead>
                        <tr class="tr-pointer">
                            <th colspan="2">Discussion&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="subdata-tr-discussion_study_finding" v-if="item_analysis['discussion_study_finding']">
                            <td style="width: 25%;">Study finding</td>
                            <td style="width: 75%;"> <span v-html="item_analysis['discussion_study_finding']"></span> </td>
                        </tr>
                        <tr class="subdata-tr-discussion_assumptions" v-if="item_analysis['discussion_assumptions']">
                            <td style="width: 25%;">Assumptions</td>
                            <td> <span v-html="item_analysis['discussion_assumptions']"></span> </td>
                        </tr>
                        <tr class="subdata-tr-discussion_comparision_with_other_studies" v-if="item_analysis['discussion_comparision_with_other_studies']">
                            <td style="width: 25%;">Comparison with other studies</td>
                            <td> <span v-html="item_analysis['discussion_comparision_with_other_studies']"></span> </td>
                        </tr>
                        <tr class="subdata-tr-discussion_limitations" v-if="item_analysis['discussion_validation']">
                            <td style="width: 25%;">Validation</td>
                            <td> <span v-html="item_analysis['discussion_validation']"></span> </td>
                        </tr>
                        <tr class="subdata-tr-discussion_generalizability_transferability" v-if="item_analysis['discussion_generalizability_transferability']">
                            <td style="width: 25%;">Transferability</td>
                            <td> <span v-html="item_analysis['discussion_generalizability_transferability']"></span> </td>
                        </tr>
                        <tr class="subdata-tr-discussion_extrapolation" v-if="item_analysis['discussion_extrapolation']">
                            <td style="width: 25%;">Extrapolation</td>
                            <td> <span v-html="item_analysis['discussion_extrapolation']"></span> </td>
                        </tr>
                        <tr class="subdata-tr-discussion_conclusion" v-if="item_analysis['discussion_conclusion']">
                            <td style="width: 25%;">Conclusion</td>
                            <td> <span v-html="item_analysis['discussion_conclusion']"></span> </td>
                                </tr>
                                <tr class="subdata-tr-discussion_comment" v-if="item_analysis['discussion_comment']">
                                    <td style="width: 25%;">Comment</td>
                                    <td> <span v-html="item_analysis['discussion_comment']"></span> </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </template>

                        <table class="comparateur2"  align="center" v-if="not_empty['base_case']" :key="key+'base_case'" :id="'base_case-'+key">
                            <thead>
                                <tr>
                                    <th :colspan="c_column" style="width:100%">Results</th>
                                </tr>
                                <tr>
                                    <th style="width:13%">Base case</th>
                                    <th style="width:17%">Comparator (Latest undominated comparator)</th>
                                    <th style="width:14%">Incremental costs</th>
                                    <th style="width:14%">Incremental outcomes </th>   

                                    <template v-if="Econ_type == 'cea'">
                                        <th style="width:10%">ICER</th>
                                        <th style="width:15%">Confidence interval(Min)</th>
                                        <th style="width:15%">Confidence interval(Max)</th>
                                    </template>

                                    <template v-else-if="Econ_type == 'cua'">
                                        <th style="width:10%">ICUR</th>
                                        <th style="width:15%">Confidence interval(Min)</th>
                                        <th style="width:15%">Confidence interval(Max)</th>
                                    </template>

                                    <template v-else-if="Econ_type == 'cma'">
                                        <th style="width:10%">Cost savings</th>
                                        <th style="width:10%">Total cost</th>
                                        <th style="width:10%">Net monetary benefit</th>
                                        <th style="width:10%">Net monetary loss</th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(valuesub, keysub) in items.economic_evaluation_analysis">
                                    <template v-for="(valuesub2 ,keysub2) in valuesub['economic_evaluation_analysis_result']">
                                        <template v-for="(valuesub3, keysub3) in valuesub2['economic_evaluation_analysis_base_case']">
                                            <template v-for="(valuesub4, keysub4) in valuesub3['economic_evaluation_analysis_basecase_comparator']" :key="keysub+'_'+keysub2+'_'+keysub3+'_'+keysub4+'_source_of_analysis'">
                                                <tr >
                                                    <td rowspan="3"> <span v-html="valuesub2['source_of_analysis'] ? valuesub2['source_of_analysis'] : '-'"></span> </td>
                                                    <td :colspan="c_column" class="text-left"><strong>Line, subgroup : </strong> {{valuesub3['line_subgroup'] ? valuesub3['line_subgroup'] : "-"}} </td>
                                                </tr>
                                                <tr>
                                                    <td> <span v-html="valuesub4['comparator'] ? valuesub4['comparator'] : '-'"></span> </td>
                                                    <td> <span v-html="valuesub4['incremental_costs'] ? valuesub4['incremental_costs'] : '-'"></span></td>
                                                    <td> <span v-html="valuesub4['incremental_outcomes'] ? valuesub4['incremental_outcomes'] : '-'"></span> </td>
                                                    <template v-if="Econ_type == 'cea'">
                                                        <td> <span v-html="valuesub4['icer'] ? valuesub4['icer'] : '-'"></span> </td>
                                                        <td> <span v-html="valuesub4['range_min'] ? valuesub4['range_min'] : '-'"></span> </td>
                                                        <td> <span v-html="valuesub4['range_max'] ? valuesub4['range_max'] : '-'"></span> </td>
                                                    </template>
                                                    <template v-else-if="Econ_type == 'cua'">
                                                        <td> <span v-html="valuesub4['icur'] ? valuesub4['icur'] : '-'"></span> </td>
                                                        <td> <span v-html="valuesub4['range_min'] ? valuesub4['range_min'] : '-'"></span> </td>
                                                        <td> <span v-html="valuesub4['range_max'] ? valuesub4['range_max'] : '-'"></span> </td>
                                                    </template>
                                                    <template v-else-if="Econ_type == 'cma'">
                                                        <td>  <span v-html="valuesub4['cost_savings'] ? valuesub4['cost_savings'] : '-'"></span> </td>
                                                        <td>  <span v-html="valuesub4['total_cost'] ? valuesub4['total_cost'] : '-'"></span> </td>    
                                                        <td>  <span v-html="valuesub4['net_monetary_benefit'] ? valuesub4['net_monetary_benefit'] : '-'"></span> </td>
                                                        <td>  <span v-html="valuesub4['net_monetary_loss'] ? valuesub4['net_monetary_loss'] : '-'"></span> </td>
                                                    </template>                                     
                                                </tr>
                                                <tr>                                      
                                                    <td :colspan="c_column" class=" text-left">
                                                        <strong>Comment : </strong> <span v-html="valuesub4['comment'] ? valuesub4['comment'] : '-'"></span>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                        <table class="comparateur2"  align="center" v-if="not_empty['deterministic']" :key="key+'deterministic'" :id="'deterministic-'+key">
                            <thead>
                                <tr>
                                    <th style="width:13%">Deterministic sensitivity analysis</th>
                                    <!--<th style="width:9%">Line, subgroup</th>-->
                                    <th style="width:17%">Comparator</th>
                                    <th style="width:28%">Parameter (change)</th>
                                    <th style="width:10%">
                                        <template v-if="Econ_type == 'cea'">
                                            ICER
                                        </template>
                                        <template v-else-if="Econ_type == 'cua'">
                                            ICUR
                                        </template>
                                    </th>
                                    <th style="width:15%">Confidence interval(Min)</th>
                                    <th style="width:15%">Confidence interval(Max)</th>
                                    <!--<th style="width:10%">Comment</th>-->
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(valuesub, keysub) in items.economic_evaluation_analysis">
                                    <template v-for="(valuesub2 ,keysub2) in valuesub['economic_evaluation_analysis_result']">
                                        <template v-for="(valuesub3, keysub3) in valuesub2['economic_evaluation_analysis_deterministic_sa']">
                                            <template v-for="(valuesub4, keysub4) in valuesub3['economic_evaluation_analysis_deterministic_sa_comparator']" :key="'value'+keysub+keysub2+keysub3+keysub4+'source_of_analysis'">
                                                <tr>
                                                    <td rowspan="3"> <span v-html="valuesub2['source_of_analysis'] ? valuesub2['source_of_analysis'] : '-'"></span> </td>
                                                    <td colspan="5" class=" text-left">
                                                        <strong>Line, subgroup : </strong> <span v-html="valuesub3['line_subgroup'] ? valuesub3['line_subgroup'] : '-'"></span> </td>
                                                </tr>
                                                <tr>
                                                    <td> <span v-html="valuesub4['comparator'] ? valuesub4['comparator'] : '-'"></span> </td>
                                                    <td> <span v-html="valuesub4['parameter'] ? valuesub4['parameter'] : '-'"></span> </td>
                                                    <td> <span v-html="parameter_change(valuesub4)"></span> </td>
                                                    <td> <span v-html="valuesub4['range_min'] ? valuesub4['range_min'] : '-'"></span> </td>
                                                    <td> <span v-html="valuesub4['range_max'] ? valuesub4['range_max'] : '-'"></span> </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="5" class=" text-left">
                                                        <strong>Comment : </strong> <span v-html="valuesub4['comment'] ? valuesub4['comment'] : '-'"></span> 
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>      
                            </tbody>                                    
                        </table>

                        <table class="comparateur2" align="center" v-if="not_empty['probabilistic']" :key="key+'probabilistic'" :id="'probabilistic-'+key">
                            <thead>
                                <tr>
                                    <th style="width:13%">Probabilistic sensitivity analysis</th>
                                    <th style="width:15%">Comparator</th>
                                    <th style="width:14%">WTP</th>
                                    <th style="width:14%">Probability</th>
                                    <th style="width:10%" class="probabilistic_net_monetary_benefit">Net monetary benefit</th>
                                    <th style="width:10%" class="probabilistic_icer">ICER </th>
                                    <th style="width:12%">Confidence interval(Min)</th>
                                    <th style="width:12%">Confidence interval(Max)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(valuesub, keysub) in items.economic_evaluation_analysis">
                                    <template v-for="(valuesub2 ,keysub2) in valuesub['economic_evaluation_analysis_result']">
                                        <template v-for="(valuesub3, keysub3) in valuesub2['economic_evaluation_analysis_probabilistic_sa']">
                                            <template v-for="(valuesub4, keysub4) in valuesub3['economic_evaluation_analysis_probabilisic_sa_comparator']"  :key="'source_of_analysis'+keysub+keysub2+keysub3+keysub4">
                                                <tr>
                                                    <td rowspan="3"> <span v-html="valuesub2['source_of_analysis'] ? valuesub2['source_of_analysis'] : '-'"></span> </td>
                                                    <td colspan="7" class=" text-left">
                                                        <strong>Line, subgroup : </strong> {{valuesub3['line_subgroup'] ? valuesub3['line_subgroup'] : "-"}} 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> <span v-html="valuesub4['comparator'] ? valuesub4['comparator'] : '-'"></span> </td>
                                                    <td> <span v-html="valuesub4['wtp'] ? valuesub4['wtp'] : '-'"></span> </td>
                                                    <td> <span v-html="valuesub4['probability'] ? valuesub4['probability'] : '-'"></span> </td>
                                                    <td class="probabilistic_net_monetary_benefit">
                                                        <span v-html="valuesub4['net_monetary_benefit']"></span>
                                                    </td>
                                                    <td class="probabilistic_icer">
                                                        <span v-html="valuesub4['icer']"></span>
                                                    </td>
                                                    <td> <span v-html="valuesub4['range_min'] ? valuesub4['range_min'] : '-'"></span> </td>
                                                    <td> <span v-html="valuesub4['range_max'] ? valuesub4['range_max'] : '-'"></span> </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="7" class=" text-left">
                                                        <strong>Comment : </strong> <span v-html="valuesub4['comment'] ? valuesub4['comment'] : '-'"></span> </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </tbody>    
                        </table>
                        
                        <table class="comparateur2" align="center" v-if="not_empty['scenario']" :key="key+'scenario'" :id="'scenario-'+key">
                            <thead>
                                <tr>
                                    <th style="width:13%">Scenario analysis</th>
                                    <th style="width:17%">Comparator</th>
                                    <th style="width:28%">Parameter (change)</th>
                                    <th style="width:10%" v-if="Econ_type == 'cea'">ICER</th>
                                    <th style="width:10%" v-else-if="Econ_type == 'cua'">ICUR</th>
                                    <th style="width:15%">Confidence interval(Min)</th>
                                    <th style="width:15%">Confidence interval(Max)</th>
                                    <!--<th style="width:10%">Comment</th>-->
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(valuesub, keysub) in items.economic_evaluation_analysis">
                                    <template v-for="(valuesub2 ,keysub2) in valuesub['economic_evaluation_analysis_result']">
                                        <template v-for="(valuesub3, keysub3) in valuesub2['economic_evaluation_analysis_scenario_analysis']">
                                            <template v-for="(valuesub4, keysub4) in valuesub3['economic_evaluation_analysis_scenario_analysis_comparator']"  :key="keysub4+'_'+keysub3+'_'+keysub2+'_'+keysub+'_source_of_analysis'">
                                                <tr>
                                                    <td rowspan="3"> <span v-html="valuesub2['source_of_analysis'] ? valuesub2['source_of_analysis'] : '-'"></span> </td>
                                                    <td colspan="5" class=" text-left">
                                                        <strong>Line, subgroup : </strong> {{valuesub3['line_subgroup'] ? valuesub3['line_subgroup'] : "-"}} 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> <span v-html="valuesub4['comparator'] ? valuesub4['comparator'] : '-'"></span> </td>
                                                    <td> <span v-html="valuesub4['parameter'] ? valuesub4['parameter'] : '-'"></span> </td>
                                                    <td v-if="Econ_type == 'cea'">  <span v-html="valuesub4['icer'] ? valuesub4['icer'] : '-'"></span> </td>
                                                    <td v-else-if="Econ_type == 'cua'"> <span v-html="valuesub4['icur'] ? valuesub4['icur'] : '-'"></span> </td>
                                                    <td> <span v-html="valuesub4['range_min'] ? valuesub4['range_min'] : '-'"></span></td>
                                                    <td> <span v-html="valuesub4['range_max'] ? valuesub4['range_max'] : '-'"></span> </td>
                                                </tr>
                                                <tr> 
                                                    <td colspan="5" class=" text-left">
                                                        <strong>Comment : </strong> <span v-html="valuesub4['comment'] ? valuesub4['comment'] : '-'"></span> </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </tbody>      
                        </table>
                        <table class="comparateur2" align="center" v-if="not_empty['sensitivity']" :key="key+'sensitivity'" :id="'sensitivity-'+key">
                            <thead>
                                <tr>
                                    <th style="width:13%">Sensitivity analysis</th>
                                    <!--<th style="width:9%">Line, subgroup</th>-->
                                    <th style="width:17%">Comparator (Latest undominated comparator)</th>
                                    <th style="width:14%">Parameter (change)/Scenario</th>
                                    <th style="width:14%">Cost savings</th>
                                    <th style="width:10%">Total cost</th>
                                    <th style="width:15%">Net monetary benefit</th>
                                    <th style="width:15%">Net monetary loss</th>
                                    <!--<th style="width:10%">Comment</th>-->
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(valuesub, keysub) in items.economic_evaluation_analysis">
                                    <template v-for="(valuesub2 ,keysub2) in valuesub['economic_evaluation_analysis_result']">
                                        <template v-for="(valuesub3, keysub3) in valuesub2['economic_evaluation_analysis_scenario_analysis']" :key="keysub+'_'+keysub2+'_'+keysub3+'_source_of_analysis'">
                                            <tr>
                                                <td rowspan="3"> <span v-html="valuesub2['source_of_analysis'] ? valuesub2['source_of_analysis'] : '-'"></span> </td>
                                                <td colspan="6" class=" text-left">
                                                    <strong>Line, subgroup : </strong> {{valuesub3['line_subgroup'] ? valuesub3['line_subgroup'] : "-" }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <template v-for="(valuesub4, keysub4) in  valuesub3['economic_evaluation_analysis_scenario_analysis_comparator']" :key="'economic_evaluation_analysis_scenario_analysis_comparator'+keysub4">
                                                        <p> <span v-html="valuesub4['comparator']"></span> </p>
                                                    </template>
                                                </td>
                                                <td> <span v-html="valuesub3['parameter_scenario'] ? valuesub3['parameter_scenario'] : '-'"></span> </td>
                                                <td> <span v-html="valuesub3['cost_savings'] ? $valuesub3['cost_savings'] : '-'"></span> </td>
                                                <td> <span v-html="valuesub3['total_cost'] ? $valuesub3['total_cost'] : '-'"></span> </td>
                                                <td> <span v-html="valuesub3['net_monetary_benefit'] ? $valuesub3['net_monetary_benefit'] : '-'"></span> </td>
                                                <td> <span v-html="valuesub3['net_monetary_loss'] ? $valuesub3['net_monetary_loss'] : '-'"></span> </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" class=" text-left">
                                                    <strong>Comment : </strong> <span v-html="valuesub3['comment'] ? $valuesub3['comment'] : '-'"></span>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </tbody>   
                        </table>
                </div>
            </template>
        </div>
<!--EvaluationEconomic end-->

</template>

<script>
import { DDMMMYY } from '../../utils'
import LinkUpload from './LinkUpload.vue'
import ImageUpload from './ImageUpload.vue'
export default {
    name : "EconomicEvaluation",
    components : {
        LinkUpload,
        ImageUpload
    },
    data() {
        return {
            not_empty : {
                'base_case' : false,
                'deterministic' : false,
                'probabilistic' : false,
                'scenario' : false,
                'sensitivity' : false,
            },
            collapse_show : false
        }
    },
    mounted() {
        for(let key in this.data){
            for(let sub_key in this.data[key].economic_evaluation_analysis){
                if(this.data[key].economic_evaluation_analysis[sub_key] && this.data[key].economic_evaluation_analysis[sub_key].economic_evaluation_analysis_result){
                    for(let sub_key1 in this.data[key].economic_evaluation_analysis[sub_key].economic_evaluation_analysis_result){
                        let data_result = this.data[key].economic_evaluation_analysis[sub_key].economic_evaluation_analysis_result[sub_key1]
                        if(data_result.economic_evaluation_analysis_base_case.length > 0){
                            this.not_empty['base_case'] = true
                        }
                        if(data_result.economic_evaluation_analysis_deterministic_sa.length > 0){
                            this.not_empty['deterministic'] = true
                        }
                        if(data_result.economic_evaluation_analysis_probabilistic_sa.length > 0){
                            this.not_empty['probabilistic'] = true
                        }
                        if(data_result.economic_evaluation_analysis_scenario_analysis.length > 0){
                            this.not_empty['scenario'] = true
                        }
                        if(data_result.economic_evaluation_analysis_sensitivity_analysis.length > 0){
                            this.not_empty['sensitivity'] = true
                        }
                    }
                }
                
            }
        }
    },
    methods : {
        DDMMMYY,
        condition_show(param) {
            return (param && param['method_patients_population_ma_population'] ||
                    param['method_patients_population_evidence_based_documented_population'] ||
                    param['method_patients_population_simulated_population'] ||
                    param['method_perspective'] ||
                    param['method_time_horizon'] ||
                    param['method_cycle_lenth'] ||
                    param['method_intervention'] ||
                    param['economic_evaluation_analysis_comparator'].length > 0 ||
                    param['discount_rate_effects'] ||
                    param['discount_rate_costs'] ||
                    param['choice_of_model'] ||
                    param['structure_model'] ||
                    param['image_name'] ||
                    param['administration_dosage'] ||
                    param['economic_evaluation_analysis_assumption'].length > 0
            ) 
        },
        parameter_change(param){
            let parameter_change = ''
            if (param['exceed'] == '1') {
                parameter_change += "> ";
            }
            if (param['less'] == '1') {
                parameter_change += "< ";
            }
            if (param['reference'] == '1') {
                parameter_change = "Reference ";
            }
            if (param['dominated'] == '1') {
                parameter_change = "Dominated ";
            }
            if (param['na'] == '1') {
                parameter_change = "N/A ";
            }

            switch (this.Econ_type) {
                case 'cea':
                    if (param['icer']) {
                        parameter_change += param['icer'];
                    }
                    break;
                case 'cua':
                    if (param['icur']) {
                        parameter_change += param['icur'];
                    }
                    break;
            }
            return parameter_change
        },
        extension(param){
           return param.split('/')[1] == 'jpeg' ? 'jpg' : param.split('/')[1]
        }
        
    },
    computed : {
        c_column(){
            let c_column = 7
            // switch (this.data[0].type_of_economic_analysis) {
            //     case 'cea':
            //         c_column = 6
            //         break;
            //     case 'cua':
            //         c_column = 6
            //         break;
            //     case 'cma':
            //         c_column = 7
            //         break;
            //     default:
            //         c_column = 6
            // }
            return c_column
        },
        Econ_type(){
            return this.data[0].type_of_economic_analysis
        }
        
    },
    props : {
        data : {
            style : Array
        }
    }
}
</script>

<style scoped>
    table.comparateur2 tr th{
        text-align: center !important;
    } 
    table.comparateur2.text-left tr td{
        text-align: left !important;
    }
</style>
