<template>
    <img v-if="srcBase64" :src="srcBase64" :class="className" :alt="alt" :style="style" />
</template>

<script>
import {get_file} from '../../services/file.service';
import { ref, onMounted } from 'vue'
export default {
    name : "ImageUpload",
    props:{
        className: String,
        src: String,
        style: String,
        alt: String,
        newtab: Boolean
    },
    setup(props) {

        const srcBase64 = ref("")

         onMounted(() => {
            const linkDownload = props.src; 
            get_file(linkDownload, "response").then(
                res => {
                    // console.log(res);
                    // console.log(res.data);
                    //console.log(res.headers['content-length']);
                    // console.log(res.headers['content-type']);
                    if(res.headers['content-length'] == 51){
                        //alert("Error, The specified blob does not exist");
                        return false;
                    }
                    const contentType = res.headers['content-type'];
                    const image64 = Buffer.from(res.data, 'binary').toString('base64')
                    srcBase64.value = `data:${contentType.toLowerCase()};base64,${image64}`;                  
                    
                    
                },
                error => {
                    return Promise.reject(error);
                }
            );
        })
 

        return { srcBase64 }
    }
}
</script>

<style>
.isLinkDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}
</style>